import React from 'react';
import Layout from '../../../components/Layout';
import { Container, Row, Column } from '../../../components/Grid';
import Section from '../../../components/Section';
import { Link } from 'gatsby';
import Image from '../../../components/Image';

const NicholasLajoie = () => (
  <Layout
    title="Alumni Spotlight - Nicholas Lajoie"
    className="alumni-center bios"
  >
    {/* Alumni Profile */}
    <Section className="alumni">
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="../../overview">Overview</Link>
          </li>
          <li className="breadcrumb">
            <Link to="../../alumni-spotlight">Alumni Spotlight</Link>
          </li>
          <li className="breadcrumb active">Nicholas Lajoie</li>
        </ul>
        <Row>
          <Column size={7} offset={1} className="alumni__profile-left pr-2">
            <h2 className="alumni__profile-greeting">Meet Alumni:</h2>
            <h1 className="alumni__profile-title">Nicholas Lajoie</h1>
            <h3 className="alumni__profile-type">
              2009 Young Scientist Challenge Finalist
            </h3>
            <h4 className="alumni__profile-project">
              Nicholas studied Computer Engineering in college and is currently
              pursuing a career in technology. He plays the drums and has worked
              in filmmaking.
            </h4>
            <h2 className="alumni__profile-question">
              What have you been up to since participating in the 3M Young
              Scientist Challenge?
            </h2>
            <p>
              After the 3M Young Scientist Challenge, I became really interested
              in filmmaking (I even got to work full-time as a Production
              Assistant on a film that was shot in my hometown!), and I actually
              considered going to film school after graduating from high school
              in 2014. However, my interest in engineering won in the end, and I
              received my Bachelor’s in Computer Engineering from the University
              of Maine in 2018 (while still pursuing film projects on the side).
              It was absolutely the right decision, and it set me up to do what
              I'm doing now: pursuing a career in technology!
            </p>
            <h2 className="alumni__profile-question">
              What were your favorite three school subjects?
            </h2>
            <p>
              Math, band (I play the drums), and science/engineering, of course!
            </p>
            <h2 className="alumni__profile-question">
              Were you involved in any school teams or clubs, if so which ones?
            </h2>
            <p>
              In high school I was involved with the chess club, played on the
              basketball and baseball teams, and played at every pep band event
              I could! Once I got to college, I helped start an
              “Entrepreneurship Club” on campus, organized trips to collegiate
              “Hackathons”, and was both a participant and organizer of the
              “UMaine Business Challenge.” I was also involved with the
              Electrical & Computer Engineering department as a student
              ambassador, and as an alumni, am currently on the ECE Visiting
              Committee.
            </p>
            <h2 className="alumni__profile-question">
              What advice do you have for a student who is considering entering
              the 3M Young Scientist Challenge? How would you say the 3M Young
              Scientist Challenge has impacted your life?
            </h2>
            <p>
              As someone who grew up on a potato farm in a very rural area in
              Maine, I assumed that I probably wasn't 'smart enough' to enter
              something like the 3M Young Scientist Challenge. Fortunately, I
              had friends and family who encouraged me to just give it a try,
              and sure enough, I became a finalist and my life was changed
              forever! So my advice is this: don't let what you perceive to be
              limitations (i.e. where you grew up, what classes you've taken,
              etc.) hold you back. It seems simple, but the phrase "if you don't
              try, you'll never know" goes a long way in life. Be bold, have
              confidence, and take chances. And don't forget to lean on your
              community to help you achieve your goals. The scientific community
              is just that: a community. Take a chance on the 3M Young Scientist
              Challenge—it showed me that I could become an engineer and change
              the world, and I will always be grateful for that!
            </p>
            <h2 className="alumni__profile-question">
              Currently, what about the 3M Young Scientist Challenge Alumni
              Network most excites you?
            </h2>
            <p>
              It's been so great to see how impactful the organization has been
              after 13 years, and I've loved catching up with some friends that
              I haven't seen since I participated in the challenge.
            </p>
          </Column>
          <Column size={4}>
            <div className="global__profile-box box-shadow">
              <div className="global__profile-image">
                <Image filename="Nicholas-LaJoie-Alumni-Quote.png" />
              </div>
              <div className="global__profile-content">
                <h4 className="quote">
                  “Take a chance on the 3M Young Scientist Challenge — it showed
                  me that I could become an engineer and change the world, and I
                  will always be grateful for that!”
                </h4>
                <h3>Nicholas Lajoie</h3>
                <p>2009 Finalist</p>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Related Alumni Profiles */}
    <Section className="related-profiles">
      <Container>
        <Row>
          <Column fullwidth>
            <h2>Related Alumni</h2>
          </Column>
        </Row>
        <Row>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Faraz-Tamboli-Alumni-Card.jpg" />
              </div>
              <div className="profile-card__content">
                <span>2019 Finalist</span>
                <h3>Faraz Tamboli</h3>
                <p>
                  Since participating in the 2019 Challenge, Faraz has continued
                  to improve and enhance his entry project, Talk Motion. Faraz
                  is currently in 9th grade and enjoys reading fantasy stories,
                  making his own music and participating in Mock Trial Club.
                </p>
                <Link to="../faraz-tamboli" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Anna-Du-Alumni-Card.jpg" />
              </div>
              <div className="profile-card__content">
                <span>2018 Finalist</span>
                <h3>Anna Du</h3>
                <p>
                  Anna participated in the 2018 3M Young Scientist Challenge and
                  continues to work on her innovation today. Now in 11
                  <sup>th</sup> grade, Anna is extremely passionate about the
                  environment and loves reading and astronomy.
                </p>
                <Link to="../anna-du" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="alumni__bio box-shadow hover imagezoom">
              <Image filename="Katie-Lampo-Alumni-Card.jpg" />
              <div className="p-1.5">
                <span>2017 Finalist</span>
                <h3>Katie Lampo</h3>
                <p>
                  Kathryn is currently a sophomore at Columbia University
                  pursuing a B.S. in Mechanical Engineering with a minor in
                  Applied Physics.
                </p>
                <Link to="../katie-lampo" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default NicholasLajoie;
